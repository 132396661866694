/**
 * Axios config
 **/

import { loadProgressBar } from 'axios-progress-bar'
import 'axios-progress-bar/dist/nprogress.css'

import { remainingLimits } from '@/services/product'
import Hootsuite from '@/services/hootsuite'
import Session from '@/services/session'
import router from '@/services/router'
import Logger from '@/services/logger'
import User from '@/services/user'

import moment from 'moment-timezone'
import axios from 'axios'
import Vue from 'vue'

// FETCH API HOST FROM ENVIRONMENT
const baseURL = process.env.VUE_APP_API_HOST || '/'

// console.log('using base url', baseURL, 'env', process.env.NODE_ENV )

const instance = axios.create({
    baseURL: baseURL,
    headers: {
        'Cache-Control': 'no-cache',
    },
})

// global axios progress bar
loadProgressBar({ showSpinner: false }, instance)

/** catch 401 and redirect to login */
const errorHandler = (error) => {
    if (error.response && error.response.status === 401 && authenticated()) {
        store.user = undefined
        return router.push('/app/login')
    }
    if (error.response && error.response.data) {
        return Promise.reject(error.response.data)
    } else if (error.response) {
        return Promise.reject(error.response)
    } else return Promise.reject(error)
}

instance.interceptors.request.use(
    function (config) {
        config.withCredentials = true
        return config
    },
    function (error) {
        return Promise.reject(error)
    },
)

instance.interceptors.response.use(
    (response) => response,
    (error) => errorHandler(error),
)

/**
 * end axios config
 **/

/**
 * TODO - REORGANIZE, USE VUEX
 **/

/**
 * Identify mobile devices
 * from enterprise codebase
 **/
const isMobile = () => {
    var check = false
    ;(function (a) {
        if (
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) ||
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
                a.substr(0, 4),
            )
        )
            check = true
    })(navigator.userAgent || navigator.vendor || window.opera)
    return check
}

/**
 * Return host when we are hosted in an iframe served by host
 **/
const isIframe = (host) => {
    var url = window.self !== window.top ? document.referrer : document.location.href
    const result = url && url.includes(host)
    return result ? host : ''
}

/**
 * Todo, these should be in getters
 **/
export const authenticated = () => {
    return store.user
}

export const admin = () => {
    return authenticated() && getters.registered() && store.user.roles.includes('admin')
}

export const onboarded = () => {
    const user = store.user
    const product = store.product
    const dashboard = store.dashboard
    const subscription = store.subscription || {}

    const needsSelect =
        dashboard &&
        dashboard.channels &&
        dashboard.channels.find((ch) => {
            return ch.needsSelection
        })
    const needsConnect =
        dashboard &&
        product &&
        ((!dashboard.channels.length &&
            product.limits.find((l) => {
                return l.id === 'connect-third-party' || l.id === 'connect-lately'
            })) ||
            (user.config && user.config.publisher === 'lately' && !user.config.publisher.onboarded))

    // console.log({ user: user._id, product: product._id, dashboard: dashboard._id, subscription, needsSelect, needsConnect })

    // differentiate v2 from v1 onboarding
    const result = subscription.version === 'V2' ? user.onboarding && user.onboarding.generator : user && product && dashboard && !needsSelect && !needsConnect && user.onboarding.publisher && user.onboarding.generator

    /**
    console.log(
        'onboarded returning',
        result,
        product.limits.filter((l) => {
            return l.id === 'connect-third-party'
        }).length,
    )
    **/
    return result
}

export const getters = {
    isIframe(host) {
        return isIframe(host)
    },
    baseUrl() {
        return baseURL
    },
    isAdmin() {
        return store.user && store.user.roles && store.user.roles.includes('admin')
    },
    ready() {
        return store.initialized
    },
    user() {
        return store.user
    },
    product() {
        return store.product
    },
    sessions() {
        return store.sessions
    },
    session() {
        return store.session
    },
    dashboard() {
        getters
        return store.dashboard
    },
    isOwner() {
        return store.isOwner
    },
    isMobile() {
        return store.isMobile
    },
    registered() {
        return store.user && store.user.state === 'complete' && getters.isSubscriptionActive()
    },
    isSubscriptionActive() {
        return store.subscription && !store.subscription.past_due && !store.subscription.pausedAt && (store.subscription.status === 'active' || store.subscription.status === 'trialing' || store.subscription.status === 'canceled')
    },
    subscription() {
        return store.subscription
    },
    membership() {
        return store.membership
    },
    status() {
        return store.status
    },
    partner() {
        const dashboard = store.dashboard
        const ch =
            dashboard && dashboard.channels
                ? dashboard.channels.find((ch) => {
                      return ch.content && ch.content.partner
                  })
                : false
        if (ch) {
            return ch.content.partner
        } else {
            return store.user.signup && store.user.signup.publisher ? store.user.signup.publisher.id : 'none'
        }
    },
}

export const setters = {
    clear() {
        store.dashboards.length = 0
        setSubscription()
        setDashboard()
        setUser()
    },
}

// for global events
export const events = new Vue()

// the store itself
export const store = Vue.observable({
    subscription: false,
    initialized: false,
    attempted: false,
    membership: false,
    keyMessages: {
        groups: [],
        messages: [],
    },
    dashboard: false,
    isOwner: false,
    isMobile: isMobile(),
    session: false,
    dashboards: [],
    product: false,
    policy: false,
    sessions: [],
    ready: false,
    user: false,
    status: {},
})

const setUser = (user, source) => {
    store.user = user
    events.$emit('user-resolved', user)
    // console.log('setUser', source)

    // init fullstory in production
    if (user && window.location.hostname === 'app.lately.ai') {
        FS.identify(user.email, {
            displayName: user.fullname,
            email: user.email,
        })
    }
}

const setIsOwner = (isOwner) => {
    store.isOwner = isOwner
}

const setSessions = (sessions) => {
    store.sessions = sessions
}

const setProduct = (product) => {
    store.product = product
    events.$emit('product-resolved', product)
}

const setPolicy = (policy) => {
    store.policy = policy
}

const setDashboard = (dashboard) => {
    store.dashboard = dashboard
    events.$emit('dashboard-resolved', dashboard)
}

const setStatus = (status) => {
    Vue.nextTick(() => {
        store.status = status
    })
}

const setKeymessages = (kms) => {
    store.keyMessages.groups = kms.groups
    store.keyMessages.messages = kms.messages
}

const setSubscription = (subscription) => {
    store.subscription = subscription
    events.$emit('subscription-resolved', subscription)
}

const setMembership = (membership) => {
    store.membership = membership
}

const setInitialized = (ready) => {
    store.initialized = ready
}

export const actions = {
    /**
     * Route guard
     * populates store when necessary, enforces onboarding goals
     **/

    async init(force, proposed = '') {
        // console.log('actions.init()',{force,authenticated:(authenticated()?true:false),initialized:store.initialized,proposed} )

        let resp = {}
        store.isMobile = isMobile()
        const loggedOut = Session.get('logged-out', false)

        try {
            if (!store.initialized || force === true) {
                const isIframe = getters.isIframe('hootsuite')

                // for hootsuite embedded obtain user info and propate to
                // server for new user creation / auto login
                if (isIframe && !loggedOut) {
                    await Hootsuite.init()
                    const member = await Hootsuite.getMemberInfo()
                    await actions.partnerLogin('hootsuite', member.userId)
                }

                resp = await instance.get(`/init?e=${isIframe}`)
                if (!resp) {
                    console.error('/init returned no resp')
                    return
                }

                // console.log('init returned', JSON.stringify(resp.data, 0, 1))

                if (resp.data) {
                    setSubscription(resp.data.subscription || false)
                    setKeymessages(resp.data.keyMessages || {})
                    setMembership(resp.data.membership || {})
                    setDashboard(resp.data.dashboard || false)
                    setSessions(resp.data.sessions || [])
                    setIsOwner(resp.data.isOwner)
                    setProduct(resp.data.product)
                    setPolicy(resp.data.policy)
                    setUser(resp.data.user, 'init')
                }

                if (resp.data.dashboard && resp.data.dashboard._id) {
                    await actions.fetchContentStatus(resp.data.dashboard._id)
                }
            }

            const user = store.user || { onboarding: {} }
            const product = store.product
            const sessions = store.sessions
            const dashboard = store.dashboard
            const subscription = store.subscription || {}
            const onboarding = user ? user.onboarding || {} : {}

            // handle all V2 subscription routes. safe to assume that dashboard exists
            if (subscription.version === 'V2') {
                let result = proposed
                if (subscription.status === 'inactive') {
                    result = '/app/subscription'
                } else if (subscription.status === 'trialing') {
                    if (!onboarding.publisher) {
                        result = `/${dashboard._id}/onboarding`
                    } else if (!onboarding.generator) {
                        result = proposed.includes('/start') || proposed.includes('/generate') || proposed.includes('/results') ? proposed : `/${dashboard._id}/onboarding`
                    }
                }
                return result && proposed !== result ? result : false
            }

            // send incomplete signups and cancelled subscriptions to /onboarding
            if (!user || !authenticated() || !dashboard || user.state !== 'complete' || !getters.isSubscriptionActive()) {
                if (loggedOut) return proposed
                else return '/app/onboarding'
            }

            // enforce onboarding
            else {
                const canConnect =
                    product &&
                    product.limits.find((l) => {
                        return l.id === 'connect-third-party' || l.id === 'connect-lately'
                    }) !== undefined
                const needsSelect =
                    dashboard.channels.filter((ch) => {
                        return ch.needsSelection
                    }).length > 0
                const needsConnect = canConnect && !dashboard.channels.length && !needsSelect

                const limits = await remainingLimits('channels', 'store.init')

                // test channel limits for users created after implementation
                const overSubscribed =
                    moment(user.createdAt).isAfter('2022-10-22', 'YYYY-MM-DD') &&
                    Object.keys(limits).filter((key) => {
                        return limits[key] < 0
                    }).length > 0

                // normalize generator onboarding
                if (
                    !User.getOnboardingKey('generator') &&
                    sessions.filter((s) => {
                        return s.bucket === 'complete'
                    }).length
                ) {
                    console.log('normalizing onboarding key', JSON.stringify(sessions, 0, 1))
                    await User.setOnboardingKey('generator', true)
                }

                // normalize publisher onboarding for non-lately partners
                if (!User.getOnboardingKey('publisher') && getters.partner() !== 'lately' && !needsConnect && !needsSelect) {
                    await User.setOnboardingKey('publisher', true)
                }

                let result = proposed

                // console.log('init',{canConnect,needsSelect,needsConnect,partner})

                // onboard publisher, or enforce overage limits
                if (canConnect && (needsConnect || needsSelect || !User.getOnboardingKey('publisher') || overSubscribed)) {
                    return `/${store.dashboard._id}/publisher`
                    // result = proposed.includes('/onboarding') ? proposed : `/${store.dashboard._id}/onboarding`
                }

                // onboard generator
                else if (!User.getOnboardingKey('generator') && (proposed === `/${dashboard._id}/sources` || proposed === `/${dashboard._id}/onboarding`)) {
                    return `/${store.dashboard._id}/start`
                    // result = proposed.includes('/onboarding') ? proposed : `/${store.dashboard._id}/onboarding`
                }

                /**
                // else if we have a prior url go there
                else if (!store.initialized && authenticated() && user.lastUrl) {
                    return router.push(user.lastUrl)
                }
                **/

                // else goto proposed
                // console.log('store', { proposed, result })
                return result
            }
        } catch (err) {
            console.error('actions.init() caught ', err)

            if (!authenticated() && proposed !== '/app/login') {
                return '/app/login'
            }

            return ''
        } finally {
            setInitialized(true)
        }
    },

    login(body) {
        const isIframe = getters.isIframe('hootsuite')
        return instance.post(`/login?e=${isIframe}`, body).then(async (resp) => {
            if (resp.status === 200) {
                Session.clear('logged-out')
                const route = await actions.init(true)
                Logger.userSessionStarted(store.user)
                return route
            } else {
                if (resp.status !== 401) {
                    console.error('login failed', resp.data)
                }
                throw new Error(resp.data.message)
            }
        })
    },

    logout() {
        return instance.post('/logout').then(() => {
            Logger.userSessionEnded()
            setters.clear()
            Session.set('logged-out', new Date().toString())
            return store
        })
    },

    fetchPolicy() {
        return instance.get('/policy').then((resp) => {
            return resp.data
        })
    },

    fetchUser() {
        return instance.get('/user').then((resp) => {
            setUser(resp.data)
            return resp.data
        })
    },

    fetchDashboards() {
        return instance.get('/dashboards').then((resp) => {
            store.dashboards.length = 0
            store.dashboards.push.apply(store.dashboards, resp.data)
            if (!store.dashboard && store.dashboards.length) {
                const dashboardId = router.currentRoute.params.dashboardId
                const dashboard = store.dashboards.find((d) => {
                    return d._id == dashboardId
                })
                setTimeout(() => {
                    actions.selectDashboard(dashboard || store.dashboards[0])
                }, 50)
            }
            return resp.data
        })
    },

    selectDashboard(dashboard) {
        setTimeout(() => {
            const ts = Math.random().toString().replace('0.', '').substring(0, 3)
            store.dashboard = dashboard
            router.push('/' + dashboard._id + '?ts=' + ts).catch(() => {})
        }, 50)
    },

    findOrCreateFile(session, uploaded) {
        return instance.post(`/${store.dashboard._id}/file`, uploaded).then(
            (resp) => {
                return resp.data
            },
            (err) => {
                Promise.reject(err)
            },
        )
    },

    findSession(id) {
        if (store.dashboard) {
            return instance.get(`/${store.dashboard._id}/session/${id}`).then(
                (resp) => {
                    store.session = resp.data
                    return resp.data
                },
                (err) => {
                    Promise.reject(err)
                },
            )
        } else throw new Error('no dashboard')
    },

    fetchSessions(paging) {
        const args = []
        args.push(`skip=${paging.page * paging.limit}`)
        args.push(`limit=${paging.limit}`)
        args.push(`sort=${paging.sort}`)
        args.push(`filter=${paging.filter}`)
        args.push(`type=${paging.type}`)
        return instance.get(`/${store.dashboard._id}/session?${args.join('&')}`).then((resp) => {
            // console.log('fetchSessions returned',JSON.stringify(resp.data,0,1) )
            return resp.data
        })
    },

    fetchSubscriptionInfo() {},

    updateSubscription(subscriptionId, mode, reason) {
        return instance.patch(`/subscription/${subscriptionId}`, {
            mode,
            reason,
        })
    },

    updateGoals(dashboardId, goals) {
        return instance.patch(`/dashboard/${dashboardId}/goals`, {
            goals: goals,
        })
    },

    fetchActivity(dashboardId, interval) {
        return instance.get(`/dashboard/${dashboardId}/activity?interval=${interval}`).then((resp) => {
            return resp.data
        })
    },

    // TODO - migrate this server side
    async createSession(type, url = '', preview = {}, submit) {
        const sourceType = type.charAt(0).toUpperCase() + type.substring(1)

        // source config for type
        const source =
            sourceType === 'Video' || sourceType === 'Audio'
                ? {
                      title: `Ingest ${sourceType} Content`,
                      source: false,
                      intro: false,
                      outro: false,
                      caption: false,
                      valid: false,
                      clip: true,
                      language: 'en-US',
                      url: '',
                  }
                : {
                      type: 'url',
                      valid: url.length > 0,
                      url: url,
                      text: '',
                      title: 'Ingest Text Content',
                      chat: '',
                  }

        // common
        const channels = {
            valid: true,
            advanced: false,
            selectedTypes: ['twitter', 'facebook', 'linkedin', 'instagram'],
            selectedLengths: {
                facebook: 500,
                twitter: 280,
                instagram: 280,
                linkedin: 600,
                pinterest: 500,
            },
            title: 'Choose Channels and Set Character Limits',
        }

        // common
        const links = {
            valid: true,
            advanced: false,
            title: 'Add a Link',
            links: {
                all: '',
                twitter: '',
                facebook: '',
                linkedin: '',
                instagram: '',
            },
        }

        const tros = {
            valid: true,
            advanced: true,
            title: 'Add Intros & Outros',
        }

        const steps =
            type === 'video' || type === 'audio'
                ? {
                      source,
                      channels,
                      links,
                      tros,
                  }
                : {
                      source,
                      channels,
                      links,
                  }

        const body = {
            isGenerative: false,
            status: {
                bucket: submit ? 'waiting' : 'open',
            },
            dashboard: store.dashboard._id,
            preview: preview,
            config: {
                showAdvanced: false,
                step: 'source',
                preview: {},
                steps: steps,

                // for audio coerce source to 'video' to invoke video processing server side
                // without reworking all to respect audio mimetypes
                // UI needs another flag to identify the actual source type
                actualSource: type,
                source: type === 'audio' ? 'video' : type, // audio processing pipeline is tied to 'video'
            },
        }

        const resp = await instance.post(`/${store.dashboard._id}/session/`, body)
        if (submit) await actions.generate(resp.data.session)
        store.sessions.push(resp.data.session)

        return resp.data
    },

    updateSession(session, args) {
        // console.log('updateSession', JSON.stringify(session,0,1))

        return instance
            .put(
                `/${store.dashboard._id}/session/${session._id}`,
                args || {
                    isGenerative: session.isGenerative,
                    config: session.config,
                },
            )
            .then((resp) => {
                const idx = store.sessions.findIndex((s) => {
                    return s._id === session._id
                })
                if (idx !== -1) store.sessions.splice(idx, 1, session)
                return resp.data
            })
    },

    removeSession(session) {
        return instance.delete(`/${store.dashboard._id}/session/${session._id}`).then((resp) => {
            const idx = store.sessions.findIndex((s) => {
                return s._id === session._id
            })
            if (idx !== -1) store.sessions.splice(idx, 1)
            return resp.data
        })
    },

    removeSessions(sessionIds) {
        return instance.delete(`/${store.dashboard._id}/sessions?ids=${sessionIds.join(',')}`).then((resp) => {
            return resp.data
        })
    },

    generate(session, tone) {
        const values = {
            _id: session._id,
            dashboard: {
                _id: session.dashboard,
            },
            config: session.config,
        }

        return instance.post(`/dashboard/${session.dashboard._id || session.dashboard}/${session._id}/generate?tone=${tone}`, values).then((resp) => {
            const idx = store.sessions.findIndex((s) => {
                return s._id === session._id
            })
            if (idx !== -1) store.sessions.splice(idx, 1, resp.data)
            session.status = resp.data.status // update session status
            Logger.sessionCreated(session)
            return resp.data
        })
    },

    fetchSessionContent(session, types, ai) {
        let url = `/content/${session.dashboard._id || session.dashboard}/${session._id}?ai=${ai}`
        if (types.length) url += `&types=${types}`
        return instance.get(url).then((resp) => {
            return resp.data
        })
    },

    fetchPost(dashboardId, post, select = '') {
        const url = `/content/${dashboardId}/post/${post._id}?select=${select}`
        return instance.get(url).then((resp) => {
            return resp.data
        })
    },

    async updatePost(session, post) {
        return instance.put(`/content/${session.dashboard}/${post._id}`, post).then(async (resp) => {
            events.$emit('post-updated', post)
            return resp.data
        })
    },

    async removePost(dashboardId, post) {
        return instance.delete(`/content/${dashboardId}/${post._id}`, { _id: post._id }).then(async (resp) => {
            Logger.postsRemoved([post])
            events.$emit('post-removed', post)
            return resp.data
        })
    },

    async removePosts(dashboardId, ids) {
        return instance.delete(`/content/${dashboardId}?ids=${ids.join(',')}`).then(async (resp) => {
            return resp.data
        })
    },

    assessPost(post) {
        const values = {
            _id: post._id,
            content: post.content,
            attachments: post.attachments,
        }

        return instance.post(`/content/assess/${post._id}`, values).then((resp) => {
            return resp.data
        })
    },

    exportContent(session) {
        return instance.get(`/content/${session.dashboard_id || session.dashboard}/${session._id}/export`)
    },

    fetchKeyMessages(key = '', group = '') {
        return instance.get(`/dashboard/${router.currentRoute.params.dashboardId}/keymessages?key=${key}&group=${group}`).then((resp) => {
            return resp.data
        })
    },

    createKeyMessage(key, group, message) {
        return instance.post(`/dashboard/${router.currentRoute.params.dashboardId}/keymessages`, { key, group, message })
    },

    removeKeyMessage(message) {
        return instance.delete(`/dashboard/${router.currentRoute.params.dashboardId}/keymessages/${message._id}`)
    },

    fetchProducts() {
        return instance.get('/products').then((resp) => {
            return resp.data
        })
    },

    createProduct(product) {
        return instance.post('/products', product)
    },

    updateProduct(product) {
        return instance.patch(`/products/${product._id}`, product)
    },

    removeProduct(product) {
        return instance.delete(`/products/${product._id}`)
    },

    fetchUsers(paging) {
        const params = []
        params.push(`skip=${paging.page * paging.limit}`)
        params.push(`limit=${paging.limit || 25}`)
        params.push(`sort=${paging.sort || '-updatedAt'}`)
        params.push(`filter=${paging.filter || 'all'}`)
        params.push(`version=${paging.version || 'V1,V2'}`)
        params.push(`search=${paging.search || ''}`)

        return instance.get(`/users?${params.join('&')}`).then((resp) => {
            return resp.data
        })
    },

    patchUser(userId, scope, updates) {
        return instance.patch(`/users/${userId}?scope=${scope}`, updates).then(async (resp) => {
            const user = await actions.fetchUser()
            setUser(user, 'patchUser')
            return resp
        })
    },

    updateUser(user) {
        return instance.put(`/users/${user._id}`, user).then((resp) => {
            Object.keys(user).forEach((key) => {
                store.user[key] = user[key]
            })
            return resp
        })
    },

    removeUser(user) {
        return instance.delete(`/users/${user._id}`).then((resp) => {
            return resp
        })
    },

    updatePassword(userId, args) {
        return instance.patch(`/users/${userId}/password`, args)
    },

    adminUpdatePassword(userId, args) {
        return instance.patch(`/users/${userId}/adminSetPassword`, args)
    },

    subscriptionKey() {
        return instance.get('/subscription/key')
    },

    fetchSubscription() {
        return instance.get('/subscription').then((resp) => {
            return resp.data
        })
    },

    createUser(user) {
        return instance.post(`/users`, user).then((resp) => {
            setUser(resp.data.user, 'createUser')
            Logger.registrationStarted(resp.data.user)
            return resp.data.user
        })
    },

    createTrialUser(user, query) {
        return instance.post(`/trial`, { user, query }).then((resp) => {
            setUser(resp.data.user, 'createTrialUser')
            setProduct(resp.data.product)
            setDashboard(resp.data.dashboard)
            setSubscription(resp.data.user.subscription)
            // Logger.registrationStarted(resp.data.user)
            return resp.data // response includes dashboard
        })
    },

    updateuser(userId, scope, values) {
        return instance.patch(`/users/${userId}?scope=${scope}`, values)
    },

    async impersonateUser(user) {
        return instance.post(`users/${user._id}/impersonate`, user).then(async (resp) => {
            await actions.init(true)
            return resp
        })
    },

    completeRegistration(user, values, plan, price) {
        return instance.post(`users/${user._id}/completeRegistration`, values).then(async (resp) => {
            await actions.init(true)
            Logger.registrationCompleted(user, plan, price)
            return resp.data
        })
    },

    completeTrialRegistration(user, values, plan, price) {
        return instance.post(`users/${user._id}/completeTrialRegistration`, values).then(async (resp) => {
            await actions.init(true)
            // Logger.registrationCompleted(user, plan, price)
            return resp.data
        })
    },

    resetPassword(email) {
        return instance.post(`users/reset-password`, { email: email })
    },

    syncProducts() {
        return instance.patch(`/products`)
    },

    addToExport(session, post) {
        session.outbox = session.outbox || []
        if (!session.outbox.includes(post._id)) {
            session.outbox.push(post._id)
        }
        return this.updateSession(
            { _id: session._id },
            {
                outbox: session.outbox,
            },
        )
    },

    // stripe self management portal
    fetchStripePortalUrl(embedded) {
        let redirect
        if (embedded) {
            redirect = window.location.origin + '/#/app/close'
        } else {
            redirect = document.location.href
        }
        return instance.get(`subscription/portal?redirect=${encodeURIComponent(redirect)}`)
    },

    updateChannels(dashboard, channels, action) {
        console.log('updateChannels', Object.keys(channels).length, action)
        return instance.patch(`/dashboard/${dashboard._id}/channels?action=${action}`, channels).then(async (resp) => {
            Logger.channelsUpdated(dashboard, channels, action)
            await actions.init(true) // reload
            return resp
        })
    },

    setDashboardWebsite(dashboardId, website) {
        return instance.patch(`/dashboard/${dashboardId}/website`, { website }).then(async (resp) => {
            await actions.init(true)
            return resp
        })
    },

    suggestHashtags(post) {
        const dashboardId = router.currentRoute.params.dashboardId
        return instance.get(`/content/${dashboardId}/${post._id}/hashtags`).then((resp) => {
            return resp.data
        })
    },

    connect(partner) {
        const url = `${getters.baseUrl()}partner/${partner}/${getters.user()._id}/${router.currentRoute.params.dashboardId}?context=${router.currentRoute.fullPath}`
        document.location.href = url
    },

    sendTo(session, post, partner, channels, schedule, noPublish, target) {
        const dashboard = getters.dashboard()

        return instance
            .post(`/${dashboard._id}/session/${session._id}/publish`, {
                post: post._id,
                partner: partner,
                channels: channels,
                schedule: schedule,
                noPublish: noPublish,
            })
            .then((resp) => {
                Logger.postSent(dashboard, partner, post, channels, target)
                return resp
            })
    },

    uploadTo(dashboardId, postId, partnerId, memberId) {
        return instance
            .post(`/content/${dashboardId}/${postId}/${partnerId}/upload`, {
                memberId,
            })
            .then((resp) => {
                return resp.data
            })
    },

    resendTo(post) {
        return instance.post(`/content/${post.dashboard}/${post._id}/resend`)
    },

    fetchContent(dashboardId, filter = {}, paging = {}) {
        const params = {
            filter,
            paging,
        }
        return instance.get(`/dashboard/${dashboardId}/content`, { params }).then((resp) => {
            return resp.data
        })
    },

    fetchArticlesFor(dashboardId, listUrl) {
        return instance.get(`/dashboard/${dashboardId}/articles?list=${encodeURIComponent(listUrl)}`).then((resp) => {
            return resp.data
        })
    },

    sendDesktopLink(dashboardId) {
        return instance.post(`/dashboard/${dashboardId}/dashboard-link`)
    },

    fetchAnalytics(dashboardId, fromDate, toDate) {
        return instance.get(`/dashboard/${dashboardId}/analytics?from=${encodeURIComponent(fromDate.toString())}&to=${encodeURIComponent(toDate.toString())}`).then((resp) => {
            return resp.data
        })
    },

    fetchAnalytics2(dashboardId, body) {
        return instance.post(`/dashboard/${dashboardId}/analytics2`, body).then((resp) => {
            return resp.data
        })
    },

    scorePost(dashboardId, postId, values) {
        return instance.post(`/content/${dashboardId}/${postId}/score`, values).then((resp) => {
            return resp.data
        })
    },

    fetchCaptionsForSource(dashboardId, options) {
        return instance.post(`/dashboard/${dashboardId}/source/${options.source}/captions`, options).then((resp) => {
            return resp.data
        })
    },

    fetchSource(dashboardId, sourceType, sourceId) {
        return instance.get(`/dashboard/${dashboardId}/source/${sourceType}:${sourceId}`).then((resp) => {
            return resp.data
        })
    },

    mergeTranscript(dashboardId, sourceType, sourceId) {
        return instance.get(`/dashboard/${dashboardId}/source/${sourceType}:${sourceId}/merge`).then((resp) => {
            return resp.data
        })
    },

    updateSource(dashboardId, sourceType, sourceId, values) {
        return instance.post(`/dashboard/${dashboardId}/source/${sourceType}:${sourceId}`, values).then((resp) => {
            return resp.data
        })
    },

    resumeSession(dashboardId, sessionId) {
        return instance.post(`/dashboard/${dashboardId}/session/${sessionId}/resume`).then((resp) => {
            return resp.data
        })
    },

    fetchRandomContent() {
        return instance.get(`/random/message`).then((resp) => {
            return resp.data
        })
    },

    fetchMembers(dashboardId) {
        return instance.get(`/dashboard/${dashboardId}/members`).then((resp) => {
            return resp.data
        })
    },

    addMember(dashboardId, values) {
        return instance.post(`/dashboard/${dashboardId}/members`, values).then((resp) => {
            return resp.data
        })
    },

    removeMember(dashboardId, email) {
        return instance.delete(`/dashboard/${dashboardId}/members/${email}`).then((resp) => {
            return resp.data
        })
    },

    fetchInvitation(dashboardId, invitationId) {
        return instance.get(`/dashboard/${dashboardId}/invitation/${invitationId}`).then((resp) => {
            return resp.data
        })
    },

    resendInvitation(dashboardId, invitationId) {
        return instance.put(`/dashboard/${dashboardId}/members/resend/${invitationId}`).then((resp) => {
            return resp.data
        })
    },

    respondToInvitation(dashboardId, invitationId, action, values) {
        return instance.post(`/dashboard/${dashboardId}/invitation/${invitationId}/${action}`, values)
    },

    fetchKeywordAnalysis(dashboardId) {
        return instance.post(`/dashboard/${dashboardId}/analysis/keywords`)
    },

    // TODO - REMOVE IF NOT USING HOOTSUITE SSO
    syncHootsuiteSocial(dashboardId, args) {
        args.userId = getters.user()._id
        return instance.post(`/partner/hootsuite/embedded/${dashboardId}`, args).then((resp) => {
            return resp.data
        })
    },

    attachmentToPublicUrl(dashboardId, contentId, idx) {
        return instance.get(`/content/${dashboardId}/${contentId}/${idx}/make-public`).then((resp) => {
            return resp.data
        })
    },

    fetchContentStatus(dashboardId) {
        if (dashboardId) {
            return instance.get(`/content/${dashboardId}/status`).then((resp) => {
                setStatus(resp.data)
                return resp.data
            })
        }
    },

    fetchOnboardStats(dashboardId) {
        if (dashboardId) {
            return instance.get(`/dashboard/${dashboardId}/onboard`).then((resp) => {
                return resp.data
            })
        }
    },

    setLastUrl(path) {
        return instance.post(`/last-url`, { path: path }).then((resp) => {
            return resp.data
        })
    },

    fetchAdminAnalytics(scope, from, to) {
        from = moment(from).format('YYYY-MM-DD')
        to = moment(to).format('YYYY-MM-DD')
        return instance.get(`/analytics/${scope}?from=${from}&to=${to}`).then((resp) => {
            return resp.data
        })
    },

    scheduleContent(dashboardId, options, rawPosts) {
        options.tz = moment.tz.guess()

        return instance.post(`/content/${dashboardId}/schedule`, { options }).then((resp) => {
            Logger.postsScheduled(rawPosts, options.channels)
            return resp.data
        })
    },

    rescheduleContent(post, publishAt) {
        let tz = moment.tz.guess()
        return instance
            .post(`/content/${post.dashboard}/${post._id}/reschedule`, {
                publishAt: publishAt,
                tz,
            })
            .then(() => {
                post.publishAt = publishAt
                return post
            })
    },

    fetchScheduledContent(contentType, fromDate, toDate) {
        return instance.get(`/content/${store.dashboard._id}/${contentType}/scheduled?fromDate=${fromDate}&toDate=${toDate}`).then((resp) => {
            return resp.data
        })
    },

    partnerLogin(partner, memberId) {
        return instance.get(`/partner-login/${partner}/${memberId}`).then((resp) => {
            return resp.data
        })
    },

    fetchBlogEntries(count, search = '') {
        return instance.get(`/blog-entries/${count}?search=${search}`).then((resp) => {
            return resp.data
        })
    },

    resubmitVideo(dashboardId, postId) {
        return instance.post(`/content/${dashboardId}/resubmit-video/${postId}`).then((resp) => {
            return resp.data
        })
    },

    cloneContent(dashboardId, postId, channels) {
        return instance.post(`/content/${dashboardId}/${postId}/clone`, { channels }).then((resp) => {
            return resp.data
        })
    },

    fetchTips(dashboardId) {
        return instance.get(`/tips/${dashboardId}`).then((resp) => {
            return resp.data
        })
    },

    gptRequest(dashboardId, goal, prompt) {
        return instance.post(`/dashboard/${dashboardId}/services/gpt`, { goal, prompt }).then((resp) => {
            return resp.data
        })
    },

    async upload(dashboardId, src) {
        return instance.post(`/dashboard/${dashboardId}/files/upload/`, { src })
    },

    async replace(dashboardId, post, existing, updated) {
        return instance.post(`/dashboard/${dashboardId}/files/replace/`, { post: post._id, existing, updated }).then((resp) => {
            return resp.data
        })
    },
}
