<template>

  <b-modal no-close-on-backdrop body-class="tone-picker" v-model="show">

    <template #modal-header="{}">
        <div class="tone-picker-header">
            <h3>What tone would you like your generated posts to be in?</h3>              
        </div>
    </template>

    <div class="voice-options">
        <p v-for="voice in voices" @click="selectedVoice=voice.id">
            <i :class="voice.id===selectedVoice? 'far fa-dot-circle':'far fa-circle'" /> {{voice.text}}
        </p>
    </div>

    <template #modal-footer="{ }">
        <div class="tone-picker-footer text-right">        
            <b-button @click="submit()">Cancel</b-button>
            <b-button @click="submit(1)" variant="primary">Generate</b-button>                
        </div>
    </template>

  </b-modal>     
  
</template>

<script>

import { getters } from '@/services/store'

export default {
  name: 'HelloWorld',
  data() {
    return {
        selectedVoice:'natural',
        voices: [{id:'natural',text:'My tone'},
            {id:'professional',text:'Official and professional'},
            {id:'conversational',text:'Relaxed and conversational'},
            {id:'humorous',text:'Light and humorous'},
            {id:'empathetic',text:'Personal, real and empathetic'},
            {id:'simple',text:'Simple words and simple structure'},
            {id:'academic',text:'Formal and academic'},
            {id:'creative',text:'Jazzy and creative'}]
    }
  },
  created() {
    this.selectedVoice = this.dflt
    let dashboard = getters.dashboard()
    if ( !dashboard || !dashboard.channels.length ) {
      this.voices[0].text = 'Natural tone of content'
    }
  },
  methods: {
    submit(save) {
      let tone = save? this.selectedVoice : ''
      this.$emit('on-tone-selected', tone)
    }
  },
  props: {
    dflt: String,
    show: Boolean
  }
}
</script>

<style lang="scss" >
.tone-picker-header {
  padding: 10px!important;
}

.tone-picker {

  padding: 20px 40px 20px 40px;

  .voice-options {
    i {
      margin-right: 10px;
    }
  }

}

.tone-picker-footer {
  button {
    margin-left: 10px!important;
  }
}

</style>
