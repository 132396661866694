<template>

    <div class="d-flex flex-row align-content-between session-list-item" v-if="session">

      <!-- selector -->
      <div class="selector" :class="{checked: selected}" @click="onSelection()">
        <i class="fa fa-check" :class="{checked:selected}" />
      </div>

      <!-- source view -->
      <div class="nopad source" align-self="center">
        <SessionSource :session="session" :policy="policy" />
      </div>

      <!-- source details -->
      <div class="info">
        <SessionInfo :session="session" :decoded="decoded" @on-action="onAction" />
      </div>

      <!-- results -->
      <div class="results ml-auto">
        <SessionResults :session="session" />
      </div>

    </div>

</template>

<script>

import GeneratorSessionHelper from '@/services/generator-session'

import SessionResults from './SessionResults'
import SessionSource from './SessionSource'
import SessionInfo from './SessionInfo'

import { actions } from '@/services/store'

export default {

  name: 'Sessions.SessionListItem',

  data() {
    return {
      generating:false,
      selected: false,
      decoded: null
    }
  },

  props: {
    session: {
      type: Object,
      required: true
    },
    policy: {
      type: Object,
      required: true
    },
    selections: {
      type: Array
    }
  },

  created() {
    this.updateGenerating()
    this.decoded = GeneratorSessionHelper.decodeSession(this.session)
  },

  watch: {

    selections: {
      handler() {
        console.log('selections changed', JSON.stringify(this.selections,0,1))
        this.selected = this.selections.includes(this.session._id)
      },
      deep:true
    }

  },

  methods: {

    updateGenerating() {
      this.generating = false // !['complete','failed'].includes(this.session.status.bucket)
    },

    onSelection() {
      this.$emit('on-select',this.session._id)
    },

    async onAction(action) {
      this.$emit('on-action', action)
    }

  },

  components: {
    SessionResults,
    SessionSource,
    SessionInfo,
  }
}

</script>

<style lang="scss" >

.session-list-item {

  font-family: Noto sans;

  margin: 0px 0px 20px 0px;
  background-color: white;
  border: 1px solid lightgrey;
  border-radius: 8px;

  box-shadow: 2px 2px 2px lightgrey;

  .selector {

    padding-top: 24px;
    text-align: center;
    min-width: 62px!important;
    background-color: #E8F8F2;
    border-radius: 8px 0px 0px 8px;
    border: 1px solid #E8F8F2!important;

    i {
      font-size:24px;
      color: white;
      padding: 2px;
      background-color: white;
      border: 1px solid  #C6C6C6;
      border-radius: 6px;
      margin: 0px 16px 0px 16px;
    }

    i.checked {
        color: white;
        background-color: #14423A!important;
        border: 1px solid  #14423A!important;
    }
  }

  .source {
    width: 25%!important;
    border-width: 0px;
  }

  .info {
    max-width: 45%;
    padding: 24px 0px 24px 0px!important;
    border-width: 0px;
  }

  .results {
    padding: 24px 0px 24px 0px!important;
    border-width: 0px;
  }

}

</style>
