<template>

    <div class="container posts-carousel-v2" v-if="session && dashboard && product" >

      <b-overlay :show="embeddedUpload || busy">

       <template #overlay>
          <div class="text-center">
            <i class="fa fa-circle-notch fa-spin fa-2x" />
            <p id="cancel-label">{{embeddedUploadMessage}}</p>
          </div>
        </template>

        <!-- errors handlers -->
        <div class="row" v-if="ready && session.postsRemaining.all <= 0">
          <div class="col-md-11 offset-1 nopad">
            <h3 v-if="!channels.length">Looks like we've processed all content for this source!</h3>
            <b-button size="sm" v-if="(session.outbox.length>0)" class="post-action-btn" :href="exportUrl('outbox')" @click="exported(session.outbox.length)">EXPORT {{session.outbox.length}} POST{{session.outbox.length>1? 'S':''}} </b-button>
          </div>
        </div>

        <!-- channel picker dialog for send -->
        <div class="row" v-if="post">
          <div  class="col-md-11 offset-1">
            <ChannelPickerModal :schedule="schedule" :dashboard="dashboard" :channel="channel" :post="post" :show="showSelectChannels" :partner="partner" :sendToTarget="sendToTarget" v-if="post" @on-close="close" @on-send="send" />
          </div>
        </div>

        <!-- schedule dialog for send -->
        <div class="row" v-if="post">
          <div  class="col-md-11 offset-1">
            <PostActionsDialog id="schedule-modal" :dashboard="dashboard" :post="post" :partner="partner" @on-send-to="onSendTo" @on-scheduled="onScheduled" />
          </div>
        </div>

        <!-- type and forward/back nav -->
        <div class="row" v-if="channels.length">
          <div class="col-md-11 offset-1">
            <ChannelBtnNav v-if="postsRemaining"
              :session="session"
              :content="content"
              :channels="channels"
              :channel="channel" :idx="idx"
              :postsRemaining="postsRemaining"
              @on-channel-selected="onChannelSelected"
              @on-prev-next="onPrevNext" />
          </div>
        </div>

        <!-- selected post -->
        <div class="row" v-if="post">
          <div class="col-md-11 offset-1">
            <Editor  :key="post._id"
                          :post="post"
                          :policy="policy"
                          :errors="errors"
                          :showSelectChannels="showSelectChannels"
                          :session="session"
                          :offset="-20"
                          @content-updated="updateContent"
                          @on-send-to="onSendTo"
                          @on-exported="onExported"
                          :partner="partner"
                          :content="content" />
          </div>
        </div>

        <!-- no remaining v1 content -->
        <div class="row" v-else-if="session.postsRemaining[channel] && showAIOriginals!=='*'">
          <div class="col-md-11 offset-1">
            <div class="row">
              <div class="col-md-9 no-v1-content">
                <h5>There is no more content to review here, but you could check out the AI Originals! </h5>
              </div>
            </div>
          </div>
        </div>

      </b-overlay>

    </div>

</template>

<script>

'use strict'

import ChannelPickerModal from '@/components/ChannelPickerModal'
import Editor from '@/components/ContentView/Editor'
import PostActionsDialog from '@/components/ContentView/PostActionsDialog'
import ChannelBtnNav from './ChannelBtnNav'

import { actions, store, getters } from '@/services/store'
import { includes } from '@/services/product'
import Hootsuite from '@/services/hootsuite'
import User from '@/services/user'
import { media } from '@/services/constants'
import Session from '@/services/session'
import { SESSION_KEYS } from '@/services/constants'

import Logger from '@/services/logger'

import Vue from 'vue';

const CHANNEL_ORDER = ['facebook','twitter','linkedin','instagram']

export default {

  name: 'PostsCarousel',

  data() {
    return {
      idx:-1,
      post:false,
      channel:'',
      errors:[],
      content:[],
      variants:[],
      partner:'',
      schedule:'',
      busy: false,
      status:false,
      ready: false,
      busyMessage:'',
      sendToTarget:'',
      postsRemaining: false,
      embeddedUpload: false,
      showAIOriginals: '*',
      embeddedUploadMessage:'',
      showSelectChannels:false,
      hootsuite: { schedule: Session.get('hootsuite-schedule','day') }
    }
  },

  props: {

    dashboard: {
      type: Object,
      required:true
    },

    session: {
      type: Object,
      required:true
    },

    policy: {
      type: Object,
      required:true
    }

  },

  computed: {

    channels() {

      let results = includes('connect-third-party,connect-lately')? Object.keys(this.session.postsRemaining).reduce((acc,key)=>{
        const connected = !this.dashboard.channels.length? [{type:key}] : this.dashboard.channels.filter((ch)=>{return ch.type===key})
        if ( media[key] && connected.length && this.session.postsRemaining[key] ) {
          acc.push(key)
        }
        return acc
      },[]) : Object.keys(this.session.postsRemaining).reduce((acc,key)=>{
        if ( this.session.postsRemaining[key] ) {
          acc.push(key)
        }
        return acc
      },[])

      // reorder
      results = CHANNEL_ORDER.reduce((acc,key)=>{
        if ( results.includes(key) ) {
          acc.push(key)
        }
        return acc
      },[])

      return results
    }

  },

  async created() {
    this.product = getters.product()
    this.partner = getters.partner()
    this.channel = this.channels.length? this.channels[0] : ''
    this.showAIOriginals = '*' // this.session.isGenerative? '*' : 'v1'
  },

  watch: {

    // capture v2 results as they become available
    async 'session.status.bucket'() {
      await this.fetchSessionContent(this.session,[this.channel],this.showAIOriginals)
      this.idx = this.content.findIndex((p)=>{return p._id == this.post._id})
    },

    'session.postsRemaining.all'() {
      if ( this.session.postsRemaining.all <= 0 && this.session.outbox.length == 0 ) {
        this.$router.push(`/${store.dashboard._id}/sources`)
      }
    },

    'session.outbox': {
      handler() {
        if ( this.session.postsRemaining.all <= 0 ) {
          this.$router.push(`/${store.dashboard._id}/sources`)
        }
      },
      deep:true
    },

    async channel() {
      await this.fetchSessionContent()
    },

  },

  methods: {

    async fetchSessionContent() {
      this.busy=true
      this.idx = -1
      this.content.length = 0
      const resp = await actions.fetchSessionContent(this.session,[this.channel],this.showAIOriginals)
      this.ready = true
      this.content = resp.results
      this.variants = resp.variants
      this.postsRemaining = resp.postsRemaining
      this.$emit('on-originals-remaining',{remaining:resp.originals,channel:this.channel})
      if ( this.content.length ) {
        this.onPrevNext(1)
      } else this.post = undefined
      this.busy = false
    },

    async onToggleOriginals(args) {
      this.showAIOriginals = (args? '*':'v1')
      this.fetchSessionContent()
    },

    onScheduled(schedule) {
      console.log('Postscarousel.onScheduled',JSON.stringify(schedule,0,1))
      this.schedule = schedule
      this.showSelectChannels = true
    },

    exportUrl(scope) {
      return `${getters.baseUrl()}content/${(this.session.dashboard._id||this.session.dashboard)}/${this.session._id}/export?scope=${scope}`
    },

    exported(count) {
      this.onExported(count)
    },

    onExported(count) {
      Vue.nextTick(()=>{
        this.session.outbox = []
        this.session.postsRemaining.all -= count
        console.log('onExported', count, this.session.postsRemaining.all)
        if ( !this.session.postsRemaining.all ) {
          return this.$router.push(`/${this.dashboard._id}/sources`);
        }
      })
    },

    close(method) {
      this.showSelectChannels = false
      this.schedule = ''
    },

    titleCase(txt) {
      const frags = txt.split(' ')
      frags.forEach((f,idx)=>{
        frags[idx] = f = f.charAt(0).toUpperCase()+f.substring(1)
      })
      return frags.join(' ')
    },

    async send(args) {

      console.log('PostsCarousel.send()', JSON.stringify(args,0,1), JSON.stringify(this.dashboard,0,1))
      
      const channels = args.channels
      const partner = getters.partner()
      const dashboard = getters.dashboard()

      // identify selected channels
      const selectedChannels = Object.keys(channels).filter((key)=>{
        return channels[key]
      }).filter((ch)=>{
        return true // ch.content
      }).map((key)=>{
        return {
          channel:key,
          dashboard: dashboard._id,
          type: dashboard.channels.find((ch)=>{return ch && ch.content && ch.content.id==key}).type // do not use identity equality!
        }
      })

      try {

        if ( this.schedule ) {

          const pickForMe =  ( this.schedule === 'pick-for-me' )
          if ( pickForMe ) {
            // derive this from a server call
            this.schedule = new Date().toISOString()
          }

          const options = {
            status:'DT',
            unit: 'd',
            number: 1,
            interval: 1,
            noPublish: true,
            startingDate: this.schedule,
            channels: selectedChannels,
            pickForMe: pickForMe,
            posts: [ this.post._id ],
            timeOfDay: [this.schedule]
          }

          console.log('scheduling', JSON.stringify(options,0,1))

          const resp = await actions.scheduleContent( this.dashboard._id, options, [this.post])
          this.$emit('on-status-message',{
            status: 'success',
            title: 'Post Scheduled!',
            message: `You can find it in your ${partner==='lately'? 'Scheduled & Published' : 'Outbox'}`
          })

        } else {

          const channelType = (this.post.channel || this.post.contentType)
          const schedule = this.partner === 'hootsuite'? args.schedule : ''
          await actions.sendTo( this.session, this.post, this.partner, selectedChannels, schedule, false, this.sendToTarget === 'queue'? 'queue':'partner' )

          const title = this.sendToTarget==='queue'? 'Post Saved' : `Post sent to ${this.titleCase(this.partner)}`
          const message = this.sendToTarget==='queue'? `You can find it in your ${this.partner==='lately'? 'Scheduler':'Drafts'}` : `You can find it in your ${this.partner==='lately'? 'Scheduled & Published':'Drafts'}`

          this.$emit('on-status-message',{
            status: 'success',
            title: title,
            message: message
          })

        }

        this.onPrevNext(0,false,true)


      } catch( err ) {

        console.error(err, err.message)
        let msg = err.message || `We seem to be having difficulties at the moment and have logged an error. You might want to try one more time, or come back in a little while.`
        msg = err.message && err.message.includes('unauthorized')? 'We were unable to send this content to Hubspot - please reauthorize your channel and try again' : msg
        this.$toasted.error(msg)
      } finally {
        this.busy = false
        window.scrollTo({ top: 0, behavior: 'smooth' });
        this.close() // close event nulls this.schedule
      }

    },

    // event handler from child components
    async onSendTo(args) {

      // console.log('PostsCarousel.onSendTo',JSON.stringify(args,0,1))

      try {

        if ( args.dest === 'hootsuite' && getters.isIframe('hootsuite') ) {

          // summarize attachments
          const acc = (this.post.attachments||[]).reduce((acc,a)=>{
            const type = a.mimetype.split('/')[0]
            acc.total += 1
            acc[type] += 1
            return acc
          },{audio:0,video:0,image:0,total:0})

          // show progress indicator
          this.embeddedUploadMessage = acc.video? 'Please wait while we prepare and upload your video' : 'Please wait while we upload your content'
          this.embeddedUpload = true

          // conditionally wait for attachments, show hsapi composer
          this.post.dashboard = getters.dashboard()._id
          await Hootsuite.composeAndAttachFor(this.post)

          // hide progress indicator
          this.embeddedUploadMessage = ''
          this.embeddedUpload = false

          this.$emit('on-status-message',{
            status: 'success',
            title: 'Post Sent to Hootsuite!',
            message: 'You can find it in your Hootsuite Dashboard and Lately Outbox'
          })

          Logger.postsSent([this.post],'hootsuite')

          this.onPrevNext(1,false,true)

        } else {

          this.sendToTarget = args.dest

          if ( args.publish ) {
            this.post.publishAt = new Date() // now
          } else {
            this.post.publishAt = 'undefined'
          }

          // save before send..
          await this.updateContent('sendTo',true)

          switch ( args.dest ) {

            case 'trash': {

              this.busy = true
              const dashboard = getters.dashboard()
              await actions.removePost(dashboard._id,this.post)
              // this.$toasted.success(`Post removed`)
              this.onPrevNext(0,false,true)

              this.$emit('on-status-message',{
                status: 'success',
                title: 'Post Removed',
                message: ''
              })

              this.busy = false
              break;

            }

            case 'export': {

              try {
                await actions.addToExport(this.session,this.post)
                this.$toasted.success(`Content added to export`)
                this.onPrevNext(0,false,true)
              } catch( err ) {
                this.$toasted.error(`Unable to ad content to export`)
              }
              break;
            }

            default: {

              // show the channel picker
              this.showSelectChannels = true
              this.doPublish = true
              break;

            }
          }
        }

      } catch( err ) {
        console.error(err)
        this.$toasted.error(err.message)
      }

    },

    onChannelSelected(channel) {
      this.channel = channel
    },

    async onPrevNext(dir,ev,evict) {
      if ( ev ) {
        ev.stopPropagation()
        ev.preventDefault()
      }
      const pr = this.session.postsRemaining
      if ( evict ) {

        const idx = this.content.indexOf(this.post)
        if ( idx !== -1 ) {
          this.content.splice(idx,1)
          pr[this.channel] -= 1
          pr.all -= 1
          this.post = false
          this.schedule = ''
        }

        // if necessary move the channel selection along
        if ( !pr[this.channel] ) {
          this.idx = -1
          this.post = false
          this.schedule = ''
          const keys = Object.keys(pr).filter((key)=>{
            return media[key] && pr[key]
          })
          if ( keys.length ) {
            this.channel = keys[0]
          } else return
        }
      }
      const next = dir + this.idx
      const length = this.content.length
      const last = this.content.length -1
      if ( length && next >= 0 && next <= last ) {
        this.idx = next
        this.post = this.content[next]
        this.schedule = ''
      }
    },

    async updateContent(source,immediate,score) {

      const clearTimer = () => {
        if ( this.timer ) {
          clearTimeout(this.timer)
          this.timer = false;
        }
      }

      const doUpdate = async () => {

        clearTimer()

        if ( this.post ) {

          const values =  {
            _id: this.post._id,
            content: this.post.content,
            publishAt: this.post.publishAt,
            attachments: this.post.attachments
          }

          if ( this.post.publishAt ) {
            values.publishAt = this.post.publishAt
          }

          if ( this.post.rank && this.post.rank.successIndicator ) {
            values.score = this.post.rank.successIndicator
          }

          const resp = await actions.updatePost( this.session, values )

          if ( resp && this.post ) this.post.preview_cache = resp.preview_cache

        }

      }

      if ( immediate) {
        await doUpdate()
      } else {
        clearTimer()
        this.timer = setTimeout(doUpdate,500)
      }

    },

    onScoreUpdated(newScore) {
      if ( !this.post.rank || this.post.rank.successIndicator != newScore ) {
        this.post.rank = this.post.rank || {}
        this.post.rank.successIndicator = newScore
        this.updateContent('onScoreUpdated',false,newScore)
      }
    },

  },

  components: {
    ChannelPickerModal,
    PostActionsDialog,
    ChannelBtnNav,
    Editor
  }

}
</script>

<style lang="scss" >

.posts-carousel-v2 {
  position: relative;
  top: -30px;
}

.source-title {
  margin: 0px;
  padding-top:0px;
}

.results-source-row {
  border-top: 1px solid lightgrey;
  width:  100vw;
  background-color:  #fff;
  position: fixed;
  top:  65px; /* ideally dynamically set this to gen header height */
  left: 0;
}

.results-source-row span {
  font-size: 12px;
  color: teal;
  padding-top:40px;
}

.results-content-row {
  padding-top:90px;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 97;
}


.results-content-row .fixed-width {
  max-width: 250px;
}

.results-content-row .results-content {
  flex-grow: 1;
}
.results-content-row
.results-nav {
  display: flex;
  justify-content: center;
  align-items: center;
}

.results-nav b-row {
  min-height:400px;
}

.results-btn {
  width:250px;
  margin:20px;
}

.results-nav {
  background-color: #fff;
  padding: 12px 18px;
  border-radius: 100px;
  margin-bottom: 100px;
  position: absolute;
  z-index: 9;
  top: 150px;
}

.editor-toggle {
  font-size: 0.5em;
}

.ai-variant-selector {
  padding-top:10px;
}

.no-v1-content {
  padding: 50px;
  background-color: white;
  border: 1px solid lightgrey;
  border-radius: 0px 0px 12px 12px;
  box-shadow: 2px 2px 2px lightgrey;
  p {
    font-size: 16px;
  }
}

</style>
