import { getters, actions } from '@/services/store'

/**
 * Helper class to deal with ideosyncrasies
 * of the underlying GeneratorSession data model
 */

class GeneratorSessionHelper {
    /**
    * Returns a template for session config usable at the client, of the form
     {
      type: ['text','media'],
      subtype: ['prompt','url','paste','audio','video'],
      tone: 'natural',
      text: {
        url:'',
        prompt:'',
        pasted:'',
        composed:false,
      },
      media:{
        link:'',          
        intro:'',
        outro:'',
        source:'',
        caption:false,
        language:'en-US'
      }    
    }
    */

    static createDetachedSession(type, subtype) {
        return {
            type,
            subtype,
            tone: 'natural',
            text: {
                url: '',
                prompt: '',
                pasted: '',
                composed: false,
            },
            media: {
                link: '',
                intro: '',
                outro: '',
                source: '',
                caption: false,
                language: 'en-US',
            },
        }
    }

    /**
     *
     * Decodes session config and returns a structure that is
     * directly usable as model for the v2 generator session
     * @param {} session
     * @returns
     */

    static decodeSession(session) {
        // session.config.actualSource  may be one of text,audio,video, but does not fully describe the session
        let configType = session.config.actualSource

        // legacy config is in session.config.steps
        let configSteps = session.config.steps

        // config.steps.source more fully describes the session
        let configSource = configSteps.source

        // get a default structure
        let config = GeneratorSessionHelper.createDetachedSession('text', 'url')

        // parse the actual session config
        // note that GeneratorSession model has significant unnecessary complexity
        // due to legacy change history - hence this helper class to encode/decode
        switch (configType) {
            // text describes prompt, pasted and url
            case 'text': {
                if (session.isGenerative) {
                    config.text.prompt = configSource.text
                    config.text.composed = true
                    config.subtype = 'prompt'
                    config.type = 'text'
                } else if (configSource.type === 'text') {
                    config.text.pasted = configSource.text
                    config.subtype = 'paste'
                    config.type = 'text'
                } else if (configSource.type === 'url') {
                    config.text.preview = configSource.preview
                    config.text.url = configSource.url
                    config.subtype = 'url'
                    config.type = 'text'
                }
                break
            }

            case 'audio':
            case 'video': {
                try {
                    // media config comes directly from configSource
                    config.media.source = configSource.source // data model attribute source is badly named here
                    config.media.intro = configSource.intro
                    config.media.outro = configSource.outro
                    config.media.caption = configSource.caption
                    config.media.language = configSource.language

                    // link config comes from steps.links
                    config.media.link = configSteps.links.links.all || '' // again overly nested data model

                    config.subtype = configType // config.media.source.mimetype.split('/')[0]
                    config.type = 'media'
                } catch (err) {
                    console.error(err)
                }
                break
            }

            default: {
                throw new Error(`unsupported type ${configType}`)
                break
            }
        }

        return config
    }

    /**
     * Map decoded params onto the provided GeneratorSession data model,
     * Note that GeneratorSession.config has legacy issues and badly needs
     * simplification/cleanup.
     * @param {*} session
     * @param {*} decoded
     */

    static async createOrUpdateSession(session, decoded) {
        if (!session) {
            let resp = await actions.createSession('new')
            session = resp.session
        }

        let config = (session.config = session.config || {
            steps: {
                source: {},
                channels: {},
                links: {},
                tros: {},
            },
        })

        // top level attribures
        session.tone = decoded.tone

        // url config
        switch (decoded.subtype) {
            case 'url': {
                config.source = 'text'
                config.actualSource = 'text'
                config.steps.source.type = 'url'
                config.steps.source.url = decoded.text.url
                break
            }
            case 'prompt': {
                config.source = 'text'
                config.actualSource = 'text'
                session.isGenerative = true
                config.steps.source.type = 'text'
                config.steps.source.text = decoded.text.prompt
                break
            }
            case 'paste': {
                config.source = 'text'
                config.actualSource = 'text'
                config.steps.source.type = 'text'
                config.steps.source.text = decoded.text.pasted
                break
            }
            case 'upload': {
                config.source = 'video'
                config.actualSource = decoded.media.source.mimetype.split('/')[0]
                config.steps.source = Object.assign({}, decoded.media)
                config.steps.source.clip = true
                if (decoded.media.link) {
                    config.steps.links.links = { all: decoded.media.link }
                }
                break
            }
        }

        await actions.updateSession(session)

        return session
    }
}

export default GeneratorSessionHelper
